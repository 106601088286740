/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

console.log("Webpacker pack'n");

import $ from 'jquery';
import { MDCDrawer } from "@material/drawer";

import PB from './2019'

// This is effectively the entry point to things.
$(document).ready(() => {
  // Set up navigation drawer
  const drawerElement = document.querySelector('.mdc-drawer--modal');
  const drawer = MDCDrawer.attachTo(document.querySelector('.mdc-drawer'));

  // Set up hamburger
  var hamburger = document.querySelector(".hamburger");
  // On click
  hamburger.addEventListener("click", () => {
    // Do something else, like open/close menu
    drawer.open = !drawer.open;
  });

  // Hook up the drawer events to the hamburger class display
  drawerElement.addEventListener("MDCDrawer:open", () => {
    hamburger.classList.add("is-active");
  });
  drawerElement.addEventListener("MDCDrawer:close", () => {
    hamburger.classList.remove("is-active");
  });

  PB.init();
});
